<script>
  import { onMount } from 'svelte';
  import { getWeightliftingProducts } from './services/dataService';

  let products = [];
  let error = null;
  let filteredProducts = [];
  let brands = [];
  let categories = [];
  let selectedBrand = 'All';
  let selectedCategory = 'All';
  let minPrice = '';
  let maxPrice = '';
  let currentSort = {
    column: null,
    ascending: true
  };
  let showFilters = false;
  let currentPage = 1;
  let itemsPerPage = 25;
  let itemsPerPageOptions = [10, 25, 50, 100];
  let viewMode = 'table';

  // New comparison variables
  let selectedForComparison = new Set();
  let comparisonProducts = [];
  let showComparisonTable = false;
  let maxCompareItems = 4;
  let currentProductIndex = 0;
  let windowWidth = typeof window !== 'undefined' ? window.innerWidth : 1024;

  $: {
    if (products.length > 0) {
      comparisonProducts = getComparisonProducts();
    }
  }

  const sortAscIcon = './img/sort-asc.svg';
  const sortDescIcon = './img/sort-desc.svg';

  onMount(async () => {
    try {
      products = await getWeightliftingProducts();
      console.log('Loaded products:', products);
      filteredProducts = products;
      brands = ['All', ...new Set(products.map(product => product.item_brand))];
      categories = ['All', ...new Set(products.map(product => product.item_category))];

      // Add window resize listener
      const handleResize = () => {
        windowWidth = window.innerWidth;
      };
      
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    } catch (error) {
      console.error('Error loading products:', error);
    }
  });

  function sortProducts(column) {
    if (currentSort.column === column) {
      currentSort.ascending = !currentSort.ascending; 
    } else {
      currentSort = { column: column, ascending: true };
    }

    filteredProducts = filteredProducts.sort((a, b) => {
      let valA = getColumnValue(a, column);
      let valB = getColumnValue(b, column);
      if (valA < valB) return currentSort.ascending ? -1 : 1;
      if (valA > valB) return currentSort.ascending ? 1 : -1;
      return 0;
    });
    currentPage = 1;
  }

  function getColumnValue(product, column) {
    switch(column) {
      case 'brand': return product.item_brand.toLowerCase();
      case 'category': return product.item_category.toLowerCase();
      case 'name': return product.item_name.toLowerCase();
      case 'details': return (product.item_weight || product.item_description || product.item_attribute || '').toLowerCase();
      case 'price': return parseFloat(product.item_price.replace(/[^0-9.-]+/g,""));
      default: return '';
    }
  }

  function filterProducts() {
    filteredProducts = products.filter(product => {
      const brandMatch = selectedBrand === 'All' || product.item_brand === selectedBrand;
      const categoryMatch = selectedCategory === 'All' || product.item_category === selectedCategory;
      const priceMatch = (!minPrice || parseFloat(product.item_price.replace(/[^0-9.-]+/g,"")) >= parseFloat(minPrice)) &&
                         (!maxPrice || parseFloat(product.item_price.replace(/[^0-9.-]+/g,"")) <= parseFloat(maxPrice));
      return brandMatch && categoryMatch && priceMatch;
    });
    currentPage = 1;
  }

  function toggleCompare(product) {
    const compositeKey = `${product.id}-${product.item_category}`;
    
    if (selectedForComparison.has(compositeKey)) {
      selectedForComparison.delete(compositeKey);
    } else {
      if (selectedForComparison.size < maxCompareItems) {
        selectedForComparison.add(compositeKey);
      } else {
        alert(`You can only compare up to ${maxCompareItems} items at once`);
        return;
      }
    }
    
    selectedForComparison = new Set(selectedForComparison);
    comparisonProducts = getComparisonProducts();
    console.log('After toggle - comparison products:', comparisonProducts);
  }

  function clearComparison() {
    selectedForComparison.clear();
    selectedForComparison = new Set();
    comparisonProducts = [];
    showComparisonTable = false;
    currentProductIndex = 0; // Reset current product index
  }

  function getComparisonProducts() {
    console.log('Products array length:', products.length);
    console.log('Selected composite keys:', Array.from(selectedForComparison));
    
    const selectedProducts = products.filter(product => {
      const compositeKey = `${product.id}-${product.item_category}`;
      const isSelected = selectedForComparison.has(compositeKey);
      console.log(`Checking product ${compositeKey}: ${isSelected}`);
      return isSelected;
    });
    
    console.log('Selected products:', selectedProducts);
    return selectedProducts;
  }

  function changeItemsPerPage() {
    currentPage = 1;
  }

  function getImagePath(product) {
    if (product.item_image_path) {
      console.log('Using image path:', product.item_image_path);
      return product.item_image_path;
    }
    console.log('Using default image');
    return 'img/default-image.jpg';
  }

  function changePage(change) {
    currentPage = Math.max(1, Math.min(currentPage + change, totalPages));
  }

  // Function for mobile navigation
  function navigateProduct(direction) {
    if (direction === 'next') {
      currentProductIndex = Math.min(comparisonProducts.length - 1, currentProductIndex + 1);
    } else {
      currentProductIndex = Math.max(0, currentProductIndex - 1);
    }
  }

  $: {
    if (products.length > 0) {
      filterProducts();
    }
  }

  $: {
    if (selectedForComparison.size > 0) {
      comparisonProducts = getComparisonProducts();
      console.log('Updated comparison products:', comparisonProducts);
    }
  }

  $: paginatedProducts = filteredProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  $: totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  $: comparisonProducts = getComparisonProducts();
  $: showCompareBar = selectedForComparison.size > 0;

  // Reset product index when comparison table is closed
  $: if (!showComparisonTable) {
    currentProductIndex = 0;
  }
</script>

<header>
  <div class="header-content">
    <div class="header-title">Weight Compare</div>
    <span class="header-version">v1.0.0</span>
  </div>
  <div class="header-controls">
    <button class="view-toggle" on:click={() => viewMode = viewMode === 'table' ? 'grid' : 'table'}>
      {viewMode === 'table' ? 'Grid View' : 'Table View'}
    </button>
    <button class="filter-toggle" on:click={() => showFilters = !showFilters}>
      {showFilters ? 'Hide Filters' : 'Show Filters'}
    </button>
  </div>
</header>

<main>
  <div class="filters" class:show={showFilters}>
    <div class="filter-group">
      <label for="brand">Brand:</label>
      <select id="brand" bind:value={selectedBrand} on:change={filterProducts}>
        {#each brands as brand}
          <option value={brand}>{brand}</option>
        {/each}
      </select>
    </div>

    <div class="filter-group">
      <label for="category">Item Category:</label>
      <select id="category" bind:value={selectedCategory} on:change={filterProducts}>
        {#each categories as category}
          <option value={category}>{category}</option>
        {/each}
      </select>
    </div>

    <div class="filter-group">
      <label for="minPrice">Min Price: $</label>
      <input id="minPrice" type="number" bind:value={minPrice} on:input={filterProducts}>
    </div>

    <div class="filter-group">
      <label for="maxPrice">Max Price: $</label>
      <input id="maxPrice" type="number" bind:value={maxPrice} on:input={filterProducts}>
    </div>

    <div class="filter-group">
      <label for="itemsPerPage">Items Per Page:</label>
      <select id="itemsPerPage" bind:value={itemsPerPage} on:change={changeItemsPerPage}>
        {#each itemsPerPageOptions as option}
          <option value={option}>{option}</option>
        {/each}
      </select>
    </div>
  </div>

  <div class="results-summary">
    Showing {(currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, filteredProducts.length)} of {filteredProducts.length} results
  </div>

  {#if viewMode === 'table'}
    <div class="table-container">
      {#if paginatedProducts.length === 0}
        <p>No products found.</p>
      {:else}
        <table>
          <thead>
            <tr>
              <th>Compare</th>
              <th on:click={() => sortProducts('brand')}>
                Brand
                <img class="sort-icon" src={currentSort.column === 'brand' ? (currentSort.ascending ? sortAscIcon : sortDescIcon) : sortDescIcon} alt="Sort">
              </th>
              <th on:click={() => sortProducts('category')}>
                Category
                <img class="sort-icon" src={currentSort.column === 'category' ? (currentSort.ascending ? sortAscIcon : sortDescIcon) : sortDescIcon} alt="Sort">
              </th>
              <th on:click={() => sortProducts('name')}>
                Name
                <img class="sort-icon" src={currentSort.column === 'name' ? (currentSort.ascending ? sortAscIcon : sortDescIcon) : sortDescIcon} alt="Sort">
              </th>
              <th on:click={() => sortProducts('details')}>
                Details
                <img class="sort-icon" src={currentSort.column === 'details' ? (currentSort.ascending ? sortAscIcon : sortDescIcon) : sortDescIcon} alt="Sort">
              </th>
              <th on:click={() => sortProducts('price')}>
                Price
                <img class="sort-icon" src={currentSort.column === 'price' ? (currentSort.ascending ? sortAscIcon : sortDescIcon) : sortDescIcon} alt="Sort">
              </th>
              <th>Company Link</th>
              <th>Amazon Link</th>
            </tr>
          </thead>
          <tbody>
            {#each paginatedProducts as product, index (product.id + '-' + index)}
              <tr>
                <td>
                  <input 
                    type="checkbox" 
                    checked={selectedForComparison.has(`${product.id}-${product.item_category}`)}
                    on:change={() => {
                      console.log('Toggling product:', product);
                      toggleCompare(product);
                    }}
                    disabled={!selectedForComparison.has(`${product.id}-${product.item_category}`) && selectedForComparison.size >= maxCompareItems}
                  />
                </td>
                <td data-label="Brand">{product.item_brand}</td>
                <td data-label="Category">{product.item_category}</td>
                <td data-label="Name">{product.item_name}</td>
                <td data-label="Details">
                  {#if product.item_weight}
                    {product.item_weight}
                  {:else if product.item_description}
                    {product.item_description}
                  {:else if product.item_attribute}
                    {product.item_attribute}
                  {:else}
                    None
                  {/if}
                </td>
                <td data-label="Price">{product.item_price}</td>
                <td data-label="Company Link"><a href={product.item_url} target="_blank">View</a></td>
                <td data-label="Amazon Link">
                  {#if product.amazon_affiliate_url}
                    <a href={product.amazon_affiliate_url} target="_blank">View</a>
                  {:else}
                    Not Available
                  {/if}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    </div>
  {:else}
    <div class="grid-container">
      {#each paginatedProducts as product, index (product.id + '-' + index)}
        <div class="grid-item">
          <div class="compare-checkbox">
            <input 
              type="checkbox" 
              checked={selectedForComparison.has(`${product.id}-${product.item_category}`)}
              on:change={() => {
                console.log('Toggling product:', product);
                toggleCompare(product);
              }}
              disabled={!selectedForComparison.has(`${product.id}-${product.item_category}`) && selectedForComparison.size >= maxCompareItems}
            />
            <label>Compare</label>
          </div>
          <div class="grid-item-image-container">
            <img 
              src={getImagePath(product)}
              alt={product.item_name || 'Product image'} 
              on:error={(e) => {
                console.error('Image failed to load:', e.target.src);
                e.target.src = '/img/default-image.png';
              }}
            />
          </div>
          <div class="grid-item-details">
            <h3>{product.item_name}</h3>
            <p><strong>Brand:</strong> {product.item_brand}</p>
            <p><strong>Category:</strong> {product.item_category}</p>
            <p><strong>Price:</strong> {product.item_price}</p>
            <p><strong>Details:</strong> 
              {#if product.item_weight}
                {product.item_weight}
              {:else if product.item_description}
                {product.item_description}
              {:else if product.item_attribute}
                {product.item_attribute}
              {:else}
                None
              {/if}
            </p>
            <a href={product.item_url} target="_blank">View Product</a>
            {#if product.amazon_affiliate_url}
              <a href={product.amazon_affiliate_url} target="_blank" class="amazon-link">View on Amazon</a>
            {:else}
              <span class="amazon-link not-available">Not Available on Amazon</span>
            {/if}
          </div>
        </div>
      {/each}
    </div>
  {/if}

  <div class="pagination">
    <button on:click={() => changePage(-1)} disabled={currentPage === 1}>Previous</button>
    <span>{currentPage} of {totalPages}</span>
    <button on:click={() => changePage(1)} disabled={currentPage === totalPages}>Next</button>
  </div>
</main>

{#if showCompareBar}
  <div class="comparison-bar">
    <div class="comparison-bar-content">
      <span>{selectedForComparison.size} item{selectedForComparison.size !== 1 ? 's' : ''} selected</span>
      <button 
        class="compare-button" 
        on:click={() => showComparisonTable = true}
      >
        Compare Items
      </button>
      <button 
        class="clear-button" 
        on:click={clearComparison}
      >
        Clear All
      </button>
    </div>
  </div>
{/if}

{#if showComparisonTable}
  <div class="comparison-modal">
    <div class="comparison-modal-content">
      <div class="comparison-header">
        <h2>Product Comparison</h2>
        <button class="close-button" on:click={() => {
          showComparisonTable = false;
          currentProductIndex = 0;
        }}>×</button>
      </div>
      
      {#if comparisonProducts.length === 0}
        <p>No products selected for comparison</p>
      {:else}
      {#if windowWidth <= 768}
  <div class="mobile-product-nav">
    <button 
      class="nav-button"
      on:click={() => currentProductIndex = Math.max(0, currentProductIndex - 1)}
      disabled={currentProductIndex === 0}
    >
      Previous
    </button>
    <span class="product-indicator">
      Product {currentProductIndex + 1} of {comparisonProducts.length}
    </span>
    <button 
      class="nav-button"
      on:click={() => currentProductIndex = Math.min(comparisonProducts.length - 1, currentProductIndex + 1)}
      disabled={currentProductIndex === comparisonProducts.length - 1}
    >
      Next
    </button>
  </div>

  <!-- Product Image -->
  <div class="mobile-product-image">
    <img 
      src={getImagePath(comparisonProducts[currentProductIndex])} 
      alt={comparisonProducts[currentProductIndex].item_name}
      on:error={(e) => {
        console.error('Image failed to load:', e.target.src);
        e.target.src = '/img/default-image.png';
      }}
    />
  </div>

  <!-- Single Compact Table -->
  <div class="mobile-comparison-table-wrapper">
    <table class="mobile-comparison-table">
      <tbody>
        <tr>
          <td class="label">Product Name</td>
          <td class="value">{comparisonProducts[currentProductIndex].item_name}</td>
        </tr>
        <tr>
          <td class="label">Brand</td>
          <td class="value">{comparisonProducts[currentProductIndex].item_brand}</td>
        </tr>
        <tr>
          <td class="label">Category</td>
          <td class="value">{comparisonProducts[currentProductIndex].item_category}</td>
        </tr>
        <tr>
          <td class="label">Price</td>
          <td class="value">{comparisonProducts[currentProductIndex].item_price}</td>
        </tr>
        <tr>
          <td class="label">Details</td>
          <td class="value">
            {#if comparisonProducts[currentProductIndex].item_weight}
              {comparisonProducts[currentProductIndex].item_weight}
            {:else if comparisonProducts[currentProductIndex].item_description}
              {comparisonProducts[currentProductIndex].item_description}
            {:else if comparisonProducts[currentProductIndex].item_attribute}
              {comparisonProducts[currentProductIndex].item_attribute}
            {:else}
              None
            {/if}
          </td>
        </tr>
        <tr>
          <td class="label">Company Link</td>
          <td class="value">
            <a href={comparisonProducts[currentProductIndex].item_url} target="_blank" class="view-button">
              View Product
            </a>
          </td>
        </tr>
        <tr>
          <td class="label">Amazon Link</td>
          <td class="value">
            {#if comparisonProducts[currentProductIndex].amazon_affiliate_url}
              <a href={comparisonProducts[currentProductIndex].amazon_affiliate_url} target="_blank" class="view-button">
                View on Amazon
              </a>
            {:else}
              Not Available
            {/if}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

    
        {:else}
          <!-- Desktop View -->
          <div class="comparison-table-wrapper">
            <table class="comparison-table">
              <thead>
                <tr>
                  <th class="attribute-column">Product</th>
                  {#each comparisonProducts as product}
                    <th class="product-column">
                      <div class="product-header">
                        <div class="comparison-product-name">{product.item_name}</div>
                        <div class="image-container">
                          <img 
                            src={getImagePath(product)} 
                            alt={product.item_name}
                            class="comparison-image"
                            on:error={(e) => {
                              console.error('Image failed to load:', e.target.src);
                              e.target.src = '/img/default-image.png';
                            }}
                          />
                        </div>
                      </div>
                    </th>
                  {/each}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="attribute-name">Brand</td>
                  {#each comparisonProducts as product}
                    <td class="product-value">{product.item_brand}</td>
                  {/each}
                </tr>
                <tr>
                  <td class="attribute-name">Category</td>
                  {#each comparisonProducts as product}
                    <td class="product-value">{product.item_category}</td>
                  {/each}
                </tr>
                <tr>
                  <td class="attribute-name">Price</td>
                  {#each comparisonProducts as product}
                    <td class="product-value price-cell">{product.item_price}</td>
                  {/each}
                </tr>
                <tr>
                  <td class="attribute-name">Details</td>
                  {#each comparisonProducts as product}
                    <td class="product-value">
                      {#if product.item_weight}
                        {product.item_weight}
                      {:else if product.item_description}
                        {product.item_description}
                      {:else if product.item_attribute}
                        {product.item_attribute}
                      {:else}
                        None
                      {/if}
                    </td>
                  {/each}
                </tr>
                <tr>
                  <td class="attribute-name">Company Link</td>
                  {#each comparisonProducts as product}
                    <td class="product-value">
                      <a href={product.item_url} target="_blank" class="view-button">
                        View Product
                      </a>
                    </td>
                  {/each}
                </tr>
                <tr>
                  <td class="attribute-name">Amazon Link</td>
                  {#each comparisonProducts as product}
                    <td class="product-value">
                      {#if product.amazon_affiliate_url}
                        <a href={product.amazon_affiliate_url} target="_blank" class="view-button">
                          View on Amazon
                        </a>
                      {:else}
                        Not Available
                      {/if}
                    </td>
                  {/each}
                </tr>
              </tbody>
            </table>
          </div>
        {/if}
      {/if}
    </div>
  </div>
{/if}

<style>
:global(body) {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  background-color: #f4f4f4;
  color: #333;
  line-height: 1.6;
}

header {
  background-color: #2c3e50;
  color: white;
  padding: 15px 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content {
  display: flex;
  flex-direction: column;
}

.header-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.header-version {
  font-size: 0.8rem;
  opacity: 0.7;
}

.filter-toggle, .view-toggle {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

main {
  padding: 80px 30px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.filters {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: none;
}

.filters.show {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.filter-group {
  display: flex;
  flex-direction: column;
}

.filter-group label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 0.9rem;
}

select, input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
}

.results-summary {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

th {
  background-color: #f8f9fa;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}

.sort-icon {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  vertical-align: middle;
}

tr:hover {
  background-color: #f5f5f5;
}

a {
  color: #3498db;
  text-decoration: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.pagination button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.grid-item {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative;
}

.grid-item:hover {
  transform: translateY(-5px);
}

.grid-item-image-container {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #f8f8f8;
}

.grid-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.grid-item-details {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.grid-item-details h3 {
  margin: 0 0 10px;
  font-size: 1.1rem;
}

.grid-item-details p {
  margin: 5px 0;
  font-size: 0.9rem;
}

/* Comparison Features Styles */
.comparison-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2c3e50;
  color: white;
  padding: 15px;
  z-index: 1000;
}

.comparison-bar-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.compare-button {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.clear-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.comparison-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.comparison-modal-content {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90vh;
  overflow: auto;
}

.comparison-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #000;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.comparison-table th,
.comparison-table td {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: center;
}

.attribute-column {
  width: 150px;
  text-align: left;
  background-color: #f8f9fa;
}

.product-column {
  min-width: 200px;
}

.comparison-product-name {
  font-weight: bold;
  font-size: 1.1rem;
  color: #2c3e50;
  margin: 10px 0;
}

.image-container {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}

.comparison-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.attribute-name {
  font-weight: bold;
  background-color: #f8f9fa;
  text-align: left;
  color: #2c3e50;
}

.product-value {
  color: #333;
}

.price-cell {
  font-weight: bold;
  color: #2c3e50;
}

.compare-checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 1;
}

input[type="checkbox"] {
    background-color: white;
  }

.view-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.view-button:hover {
  background-color: #2980b9;
}

.header-controls {
  display: flex;
  gap: 10px;
}

.amazon-link {
  display: block;
  margin-top: 8px;
  color: #0066c0;
  text-decoration: none;
}

.amazon-link:hover {
  text-decoration: underline;
}

.amazon-link.not-available {
  color: #666;
  cursor: default;
}

.amazon-link.not-available:hover {
  text-decoration: none;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  header {
    padding: 15px;
  }

  main {
    padding: 80px 15px 20px;
  }

  .mobile-product-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
  }

  .nav-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    min-width: 80px;
  }

  .nav-button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }

  .product-indicator {
    font-size: 0.9rem;
    color: #666;
  }

  .mobile-product-header {
    text-align: center;
    margin-bottom: 20px;
  }

  .mobile-product-image {
    text-align: center;
    margin: 20px 0;
  }

  .mobile-product-image img {
    max-width: 200px;
    max-height: 200px;
    margin: 0 auto;
  }

  
  .mobile-comparison-table-wrapper {
    margin: 0 10px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .mobile-comparison-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
  }

  .mobile-comparison-table tr {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
  }

  .mobile-comparison-table tr:last-child {
    border-bottom: none;
  }

  .mobile-comparison-table td {
    padding: 15px;
    line-height: 1.4;
  }

  .mobile-comparison-table td.label {
    width: 35%;
    font-weight: 600;
    color: #2c3e50;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    border-right: 1px solid #eee;
  }

  .mobile-comparison-table td.value {
    width: 65%;
    text-align: left;        /* Change from right to left */
    justify-content: flex-start;  /* Change from flex-end to flex-start */
    display: flex;
    align-items: center;
    padding-left: 15px;      /* Add some padding on the left */
  }

  /* Remove any margin between rows */
  .mobile-comparison-table tr + tr {
    margin-top: 0;
  }

  .mobile-comparison-table .view-button {
    margin-left: 0;          /* Remove any left margin */
  }

  .table-container {
    background-color: #f4f4f4;
    padding: 15px;
  }

  table thead {
    display: none;
  }

  table tr {
    margin-bottom: 20px;
    display: block;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 15px;
  }

  table td {
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    font-size: 14px;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }

  table td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    color: #666;
    padding-right: 10px;
  }

  table td:last-child {
    border-bottom: none;
  }

  .comparison-modal-content {
    padding: 15px;
    margin: 10px;
    width: 95%;
  }

  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .grid-item-image-container {
    height: 150px;
  }

  .grid-item-details h3 {
    font-size: 1rem;
  }

  .grid-item-details p {
    font-size: 0.8rem;
  }

  .comparison-product-name {
    font-size: 1rem;
  }

  .nav-button {
    min-width: 70px;
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  .product-indicator {
    font-size: 0.8rem;
  }
}
</style>